












































































































































































import {
  OrgHeaderInfo,
  MolGuideLink,
  MolGuideLinkArticleEnum,
  AtomButton,
  AtomButtonTypeEnum,
  OrgTable,
  IOrgTableProps,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomDate,
  AtomSwitcher,
  MolSearchBox,
  MolPagination,
  MolTableAction,
  MolTableActionTypeEnum,
  MolModalConfirm,
} from "@/v2/new-design-system";
import { t, tc } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, watch, onMounted } from "@vue/composition-api";
import { CreateMenuModal } from "../create-menu-modal";
import { useDebounceValue } from "@/composable";
import {
  fetchMenusFromBusiness,
  IFetchMenusFromBusinessResponse,
} from "@/v2/repo/fetch-menus-from-business";
import { useRouter } from "@/router";
import { updateMenuStatus } from "@/v2/repo/menu/update-menu-status";
import { deleteMenu } from "@/v2/repo/menu/delete-menu";
import { Toast } from "@/design-system";
import { DuplicateMenuModal } from "../duplicate-menu-modal";
import { OrderingServiceOutletEnum } from "@/v2/enum/ordering-service.enum";
import { ConnectionMenuModal } from "../connection-menu-modal";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";

const css = bemBuilder("menus-page");

interface MenuData {
  id: string;
  name: string;
  state: "draft" | "live";
  isDefault: boolean;
  updatedAt: Date;
  attachedOutlets: Array<{
    outletId: string;
    orderingServices: Array<OrderingServiceOutletEnum>;
  }>;
}
enum MenuStatusEnum {
  DRAFT = "draft",
  LIVE = "live",
}
export default defineComponent({
  name: "MenusPage",
  components: {
    CreateMenuModal,
    AtomButton,
    AtomText,
    MolPagination,
    AtomDate,
    AtomSwitcher,
    MolSearchBox,
    MolGuideLink,
    OrgHeaderInfo,
    OrgTable,
    MolTableAction,
    MolModalConfirm,
    DuplicateMenuModal,
    ConnectionMenuModal,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const showCreateMenuModal = ref(false);
    const currentSortDir = ref<"DESC" | "ASC">("DESC");

    const menus = ref<IFetchMenusFromBusinessResponse["data"]>([]);

    const pagination = ref<IFetchMenusFromBusinessResponse["pagination"]>({
      currentPage: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
    });

    function hasIntegration(sourceId: number): boolean {
      return ![
        MenuManagementSourceByIdEnum.CHATFOOD,
        MenuManagementSourceByIdEnum.OMNIVORE,
        MenuManagementSourceByIdEnum.TEVALIS,
      ].includes(sourceId);
    }

    function disableToggleMenu(isDefault: boolean, sourceId: number): boolean {
      if (hasIntegration(sourceId)) return true;
      return isDefault;
    }

    const router = useRouter();

    function goToMenuPage(menuId: string) {
      router.push({
        name: "menus.edit",
        params: {
          businessId: props.businessId,
          menuId: menuId,
        },
      });
    }

    function usedByLabel(attachedOutlets: number): string {
      return attachedOutlets === 0
        ? t("module.menu.main.table.no_connections")
        : tc("module.menu.main.table.connected_outlets", attachedOutlets);
    }

    const menuData = ref<MenuData[]>([]);

    async function getMenuList(page: number) {
      let response: IFetchMenusFromBusinessResponse | undefined;

      isLoading.value = true;

      try {
        response = await fetchMenusFromBusiness(
          props.businessId,
          page,
          searchQuery.value,
          currentSortDir.value
        );
      } catch (e: any) {
        new Toast().create({
          type: "error",
          title: t("module.menu.main.table.error_message_on_update_title"),
          text: t("module.menu.main.table.error_message_on_update_content"),
        });
      } finally {
        isLoading.value = false;
      }

      if (response) {
        menuData.value = response.data;
        pagination.value = response.pagination;
      }
    }

    const searchQuery = ref("");
    const { debouncedValue } = useDebounceValue(searchQuery, 500);
    watch(debouncedValue, () => getMenuList(1));

    async function toggleMenuStatus(id: string, state: boolean, name: string) {
      const status = state ? MenuStatusEnum.LIVE : MenuStatusEnum.DRAFT;

      try {
        await updateMenuStatus(props.businessId, id, status);

        new Toast().create({
          type: "success",
          title: t("module.menu.main.table.success_message_on_update_title", {
            menuName: name,
          }),
          text: t("module.menu.main.table.success_message_on_update_content"),
        });
      } catch (e: any) {
        new Toast().create({
          type: "error",
          title: t("module.menu.main.table.error_message_on_update_title", {
            menuName: name,
          }),
          text: t("module.menu.main.table.error_message_on_update_content"),
        });
      }

      getMenuList(pagination.value.currentPage);
    }

    const tableColumnsConfig: IOrgTableProps["columns"] = {
      status: {
        header: () => "",
        show: true,
        sortable: false,
        width: 70,
        sortDirection: "NONE",
      },
      name: {
        header: () => t("module.menu.main.table.name"),
        show: true,
        sortable: false,
        width: 200,
        sortDirection: "NONE",
      },
      usedBy: {
        header: () => t("module.menu.main.table.used_by"),
        show: true,
        sortable: false,
        width: 200,
        sortDirection: "NONE",
      },
      source: {
        header: () => t("module.menu.main.table.source"),
        show: true,
        sortable: false,
        width: 150,
        sortDirection: "NONE",
      },
      lastUpdated: {
        header: () => t("module.menu.main.table.last_updated"),
        show: true,
        sortable: true,
        width: 160,
        sortDirection: "DESC",
        align: "right",
      },
    };

    onMounted(() => {
      getMenuList(1);
    });

    function onSort(key: string, dir: "ASC" | "DESC") {
      if (currentSortDir.value === dir) return;

      currentSortDir.value = dir;
      getMenuList(1);
    }

    const deleteModal = ref(false);
    const deleteMenuData = ref({
      id: "",
      name: "",
      isDefault: false,
    });

    function showDeleteModal(
      menuId: string,
      menuName: string,
      isDefault: boolean
    ) {
      if (isDefault) {
        new Toast().create({
          type: "error",
          title: t("module.menu.main.table.delete_default_menu_title"),
          text: t("module.menu.main.table.delete_default_menu_subtitle"),
        });
        return;
      }
      deleteModal.value = true;
      deleteMenuData.value = {
        id: menuId,
        name: menuName,
        isDefault: isDefault,
      };
    }

    async function deleteMenuItem(menuId: string) {
      const response = ref(false);

      try {
        response.value = await deleteMenu({
          businessId: props.businessId,
          menuId: menuId,
        });
      } catch (e: any) {
        deleteModal.value = false;
        deleteMenuData.value = {
          id: "",
          name: "",
          isDefault: false,
        };
        new Toast().create({
          type: "error",
          title: t("module.menu.main.table.error_delete_menu_title", {
            menuName: deleteMenuData.value.name,
          }),
          text: t("module.menu.main.table.error_delete_menu_message"),
        });
      }

      if (!response.value) return;

      deleteModal.value = false;

      new Toast().create({
        type: "success",
        title: t("module.menu.main.table.success_delete_menu_title", {
          menuName: deleteMenuData.value.name,
        }),
        text: t("module.menu.main.table.success_delete_menu_message"),
      });

      getMenuList(pagination.value.currentPage);
    }

    const showDuplicateMenuModal = ref(false);

    const menuDataDuplicated = ref({
      menuId: "",
      menuName: "",
      menuNotes: "",
    });

    function duplicateMenu(
      menuId: string,
      menuName: string,
      menuNotes: string
    ) {
      const menuNameCopy = t(
        "module.menu.main.table.duplicate_menu_title_copy",
        {
          menuName: menuName,
        }
      );
      menuDataDuplicated.value = {
        menuId,
        menuName: menuNameCopy,
        menuNotes,
      };
      showDuplicateMenuModal.value = true;
    }

    function reloadData() {
      getMenuList(1);
    }

    const showConnectionMenuModal = ref(false);
    const connectionMenuId = ref("");
    const connectionMenuName = ref("");
    const connectionMenuSourceId = ref<MenuManagementSourceByIdEnum>();
    const connectionMenuSourceIsEditable = ref(false);
    const connectionMenuSourceIsIntegration = ref(false);
    const selectedOutlets = ref();

    function openConnectionMenuModal(
      id: string,
      name: string,
      sourceId: MenuManagementSourceByIdEnum,
      sourceIsEditable: boolean,
      sourceIsIntegration: boolean
    ) {
      showConnectionMenuModal.value = true;
      connectionMenuId.value = id;
      connectionMenuName.value = name;
      connectionMenuSourceId.value = sourceId;
      connectionMenuSourceIsEditable.value = sourceIsEditable;
      connectionMenuSourceIsIntegration.value = sourceIsIntegration;
      selectedOutlets.value = menuData.value
        .filter((data) => data.id === id)
        .flatMap(({ attachedOutlets }) =>
          attachedOutlets.map((outlet) =>
            outlet.orderingServices.flatMap(
              (service) => `${outlet.outletId}:${service}`
            )
          )
        )
        .flat();
    }

    return {
      t,
      tc,
      css,
      menuData,
      isLoading,
      searchQuery,
      showCreateMenuModal,
      disableToggleMenu,
      onSort,
      usedByLabel,
      toggleMenuStatus,
      tableColumnsConfig,
      goToMenuPage,
      hasIntegration,
      MolGuideLinkArticleEnum,
      getMenuList,
      AtomButtonTypeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      menus,
      pagination,
      MolTableActionTypeEnum,
      deleteMenuItem,
      deleteModal,
      deleteMenuData,
      showDeleteModal,
      duplicateMenu,
      menuDataDuplicated,
      showDuplicateMenuModal,
      reloadData,
      openConnectionMenuModal,
      connectionMenuId,
      connectionMenuName,
      connectionMenuSourceId,
      connectionMenuSourceIsEditable,
      connectionMenuSourceIsIntegration,
      showConnectionMenuModal,
      selectedOutlets,
    };
  },
});
