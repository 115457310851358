import { http } from "../../http";
import { IRepoErrors } from "../../errors";

interface IDeleteMenuBody {
  businessId: string;
  menuId: string;
}

export class DeleteMenuError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: DeleteMenu");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function deleteMenu(request: IDeleteMenuBody): Promise<boolean> {
  let response: boolean;
  try {
    response = await http.delete(
      `/api/v3/businesses/${request.businessId}/menus/${request.menuId}`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new DeleteMenuError(error);
  }

  return Boolean(response);
}
