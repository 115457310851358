import { http } from "../../http";
import { IRepoErrors } from "../../errors";

export class UpdateMenuStatusError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: UpdateMenuStatus");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

enum MenuStatusEnum {
  DRAFT = "draft",
  LIVE = "live",
}

interface IUpdateMenuStatusResponse {
  status: MenuStatusEnum;
}

export async function updateMenuStatus(
  businessId: string,
  menuId: string,
  state: MenuStatusEnum
): Promise<IUpdateMenuStatusResponse> {
  let response: IUpdateMenuStatusResponse;

  try {
    response = await http.put(
      `/api/v3/businesses/${businessId}/menus/${menuId}/update-state`,
      { state: state }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new UpdateMenuStatusError(error);
  }

  return response;
}
