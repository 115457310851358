






























































































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  watch,
  PropType,
  computed,
  onMounted,
  onBeforeUnmount,
} from "@vue/composition-api";
import {
  AtomButton,
  AtomModal,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomButtonTypeEnum,
  AtomCheckbox,
  OrgFormInput,
  OrgFormTextArea,
} from "@/v2/new-design-system";
import {
  createMenu,
  ICreateMenuPayload,
  ICreateMenuResponse,
} from "@/v2/repo/menu/create-menu";
import { fetchBusinessActiveIntegration } from "@/v2/repo/fetch-business-active-integration";
import { unWatchFlag, watchFlag } from "@/v2/core/feature-flag";
import { useRouter } from "@/router";
import { report } from "@chatfood/bug-reporter";

const css = bemBuilder("create-menu-modal");

export default defineComponent({
  name: "CreateMenuModal",
  components: {
    AtomButton,
    AtomModal,
    AtomText,
    AtomCheckbox,
    OrgFormInput,
    OrgFormTextArea,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const isFromPos = ref(false);
    const hasIntegration = ref(false);
    const hideSnoozedItems = ref(false);
    const integrationSourceId = ref();

    const maxLengthMessage = 25;

    const entry = ref<{
      name: string;
      notes: string;
      summary: string;
      hideSnoozedItems: boolean;
    }>({
      name: "",
      notes: "",
      summary: "",
      hideSnoozedItems: false,
    });

    const errors = ref({
      name: "",
      notes: "",
      summary: "",
    });

    const router = useRouter();

    const messageCounter = computed(() => {
      return t("module.menu.create_menu_modal.x_characters", {
        length: entry.value.name.length,
        max: maxLengthMessage,
      });
    });

    // Feature flag for the omnivore integration
    const omnivoreIntegration = ref(false);
    function updateOmnivoreFlag(val: boolean) {
      omnivoreIntegration.value = val;
    }

    onMounted(() => {
      watchFlag("omnivore", updateOmnivoreFlag);
    });

    onBeforeUnmount(() => {
      unWatchFlag("omnivore", updateOmnivoreFlag);
    });

    onMounted(() => {
      fetchPosIntegration();
    });

    watch(
      () => omnivoreIntegration.value,
      () => fetchPosIntegration()
    );

    async function fetchPosIntegration() {
      if (!omnivoreIntegration.value) return;

      try {
        const res = await fetchBusinessActiveIntegration(props.businessId);

        if (res) {
          hasIntegration.value = true;
          integrationSourceId.value = res.id;
        }
      } catch (error) {
        report(error);
      }
    }

    const submitCreateMenu = async () => {
      isLoading.value = true;
      let response: ICreateMenuResponse | undefined;

      try {
        let payload: ICreateMenuPayload = {
          name: entry.value.name,
          notes: entry.value.notes,
          summary: entry.value.summary,
          hide_snoozed_items: entry.value.hideSnoozedItems,
        };

        if (isFromPos.value) {
          payload = {
            ...payload,
            source: integrationSourceId.value,
          };
        }

        response = await createMenu(props.businessId, payload);
      } catch (e: any) {
        if (e.errors) {
          errors.value.name = e.errors.name ? e.errors.name[0] : "";
          errors.value.notes = e.errors.notes ? e.errors.notes[0] : "";
          errors.value.summary = e.errors.summary ? e.errors.summary[0] : "";
        } else {
          report(e.message, {
            context: {
              error: e,
            },
            tags: {
              "http.response_code": e.statusCode,
            },
          });
        }
      } finally {
        isLoading.value = false;
      }

      if (!response) return;

      props.onClose();

      router.push({
        name: "menus.edit",
        params: {
          businessId: props.businessId,
          menuId: response.id,
        },
      });
    };

    return {
      t,
      css,
      entry,
      isLoading,
      errors,
      isFromPos,
      hasIntegration,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      AtomButtonTypeEnum,
      submitCreateMenu,
      maxLengthMessage,
      messageCounter,
      omnivoreIntegration,
    };
  },
});
