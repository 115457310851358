

























































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, PropType } from "@vue/composition-api";
import {
  AtomButton,
  AtomModal,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomButtonTypeEnum,
  OrgFormInput,
  OrgFormTextArea,
} from "@/v2/new-design-system";
import { duplicateMenu } from "@/v2/repo/menu/duplicate-menu";
import { Toast } from "@/design-system";
import { report } from "@chatfood/bug-reporter";

const css = bemBuilder("create-menu-modal");

export default defineComponent({
  name: "DuplicateMenuModal",
  components: {
    AtomButton,
    AtomModal,
    AtomText,
    OrgFormInput,
    OrgFormTextArea,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    menuId: {
      type: String,
      required: true,
    },
    menuName: {
      type: String,
      required: true,
    },
    menuNotes: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onSuccess: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const entry = ref<{ name: string; notes: string }>({
      name: props.menuName,
      notes: props.menuNotes,
    });
    const errors = ref({
      name: "",
      notes: "",
    });
    const isLoading = ref(false);

    const submitCreateMenu = async () => {
      if (entry.value.name.trim().length < 2) {
        return (errors.value.name = t(
          "module.menu.duplicate_menu_modal.name_error"
        ));
      }

      isLoading.value = true;
      const response = ref(false);

      try {
        response.value = await duplicateMenu(
          {
            businessId: props.businessId,
            menuId: props.menuId,
          },
          {
            name: entry.value.name,
            notes: entry.value.notes,
          }
        );
      } catch (e: any) {
        if (e.errors && (e.errors.name || e.errors.notes)) {
          errors.value.name = e.errors.name[0];
          errors.value.notes = e.errors.notes[0];
          return;
        }

        props.onClose();
        new Toast().create({
          type: "error",
          title: t(
            "module.menu.duplicate_menu_modal.error_duplicate_menu_title",
            {
              menuName: entry.value.name,
            }
          ),
          text: t(
            "module.menu.duplicate_menu_modal.error_duplicate_menu_message"
          ),
        });
        report(e);
      } finally {
        isLoading.value = false;
      }

      if (!response.value) return;

      props.onClose();
      props.onSuccess();
      new Toast().create({
        type: "success",
        title: t(
          "module.menu.duplicate_menu_modal.success_duplicate_menu_title",
          {
            menuName: entry.value.name,
          }
        ),
        text: t(
          "module.menu.duplicate_menu_modal.success_duplicate_menu_message"
        ),
      });
    };

    return {
      t,
      css,
      entry,
      isLoading,
      errors,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      AtomButtonTypeEnum,
      submitCreateMenu,
    };
  },
});
