import { http } from "../../http";
import { IAxiosErrorResponse } from "../types";

interface IDuplicateMenuBody {
  businessId: string;
  menuId: string;
}

export async function duplicateMenu(
  request: IDuplicateMenuBody,
  payload: {
    name: string;
    notes: string;
  }
): Promise<boolean> {
  let response: boolean;

  try {
    response = await http.post(
      `/api/v3/businesses/${request.businessId}/menus/${request.menuId}/duplicate`,
      payload
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;

    const error = {
      status: response.status,
      message: rawErrorMessage || "The request to duplicate a menu has failed.",
      response: response.data,
    };
    throw error.response;
  }

  return Boolean(response);
}
