var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('div',{class:_vm.css('split-row')},[_c('OrgHeaderInfo',{attrs:{"heading":_vm.t('module.menu.main.title'),"sub-heading":_vm.t('module.menu.main.subtitle')},scopedSlots:_vm._u([{key:"links",fn:function(){return [_c('MolGuideLink',{attrs:{"article":_vm.MolGuideLinkArticleEnum.LEARN_HOW_MANAGE_MENUS}})]},proxy:true}])}),_c('AtomButton',{attrs:{"on-click":function () { return (_vm.showCreateMenuModal = true); }}},[_vm._v(" "+_vm._s(_vm.t("module.menu.main.create_menu"))+" ")])],1),_c('div',{class:_vm.css('split-row')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.H3}},[_vm._v(" "+_vm._s(_vm.tc("module.menu.main.table.menus_count", _vm.menuData.length))+" ")]),_c('MolSearchBox',{attrs:{"placeholder":_vm.t('module.menu.main.search_menu_placeholder'),"on-input":function (key) { return (_vm.searchQuery = key); }}})],1),_c('OrgTable',{class:_vm.css('table'),attrs:{"columns":_vm.tableColumnsConfig,"loading":_vm.isLoading,"has-bulk-selection":false,"data":_vm.menuData,"has-actions":true,"no-records-msg":_vm.t('module.menu.main.table.empty_table'),"total-records":10,"row-click":function (row) { return _vm.goToMenuPage(row.id); },"on-sort":_vm.onSort},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var row = ref.row;
return [_c('AtomSwitcher',{attrs:{"size":"mini","checked":row.state === 'live' ? true : false,"disabled":_vm.disableToggleMenu(row.isDefault, row.source.id),"on-change":function (state) {
            _vm.toggleMenuStatus(row.id, state, row.name);
          }}})]}},{key:"name",fn:function(ref){
          var row = ref.row;
return [_c('div',{class:_vm.css('name-row')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.BODY_BOLD,"color":row.state === 'live'
              ? _vm.AtomTextColorEnum.MIDNIGHT
              : _vm.AtomTextColorEnum.RAINY}},[_vm._v(" "+_vm._s(row.name)+" ")]),(row.isDefault)?_c('AtomText',{class:_vm.css('badge'),attrs:{"data-test":"is-default","type":_vm.AtomTextTypeEnum.SMALL_BOLD}},[_vm._v(" "+_vm._s(_vm.t("module.menu.main.table.default"))+" ")]):_vm._e()],1)]}},{key:"usedBy",fn:function(ref){
              var row = ref.row;
return [_c('a',{class:_vm.css('connections'),attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () { return _vm.openConnectionMenuModal(
              row.id,
              row.name,
              row.source.id,
              row.source.isEditable,
              row.source.isIntegration
            ); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.usedByLabel(row.attachedOutlets.length))+" ")])]}},{key:"source",fn:function(ref){
            var row = ref.row;
return [_c('AtomText',[_vm._v(_vm._s(row.source.name))])]}},{key:"lastUpdated",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:_vm.css('updated')},[_c('AtomText',[_c('AtomDate',{attrs:{"datetime":row.updatedAt,"format":"DD MMMM YYYY HH:mm"}})],1)],1)]}},{key:"actions",fn:function(ref){
            var row = ref.row;
return [(!_vm.hasIntegration(row.source.id))?_c('div',{class:_vm.css('actions')},[_c('MolTableAction',{attrs:{"tooltip-text":_vm.t('module.menu.main.table.tooltip_duplicate_cta'),"icon-name":"copy","on-click":function () { return _vm.duplicateMenu(row.id, row.name, row.notes); }}}),_c('MolTableAction',{attrs:{"tooltip-text":_vm.t('module.menu.main.table.tooltip_delete_cta'),"icon-name":"delete","type":_vm.MolTableActionTypeEnum.DANGER,"on-click":function () { return _vm.showDeleteModal(row.id, row.name, row.isDefault); }}})],1):_vm._e()]}}])}),(_vm.showCreateMenuModal)?_c('CreateMenuModal',{attrs:{"business-id":_vm.businessId,"on-close":function () { return (_vm.showCreateMenuModal = false); }}}):_vm._e(),(_vm.showDuplicateMenuModal)?_c('DuplicateMenuModal',{attrs:{"business-id":_vm.businessId,"menu-id":_vm.menuDataDuplicated.menuId,"menu-name":_vm.menuDataDuplicated.menuName,"menu-notes":_vm.menuDataDuplicated.menuNotes,"on-close":function () { return (_vm.showDuplicateMenuModal = false); },"on-success":function () { return _vm.reloadData(); }}}):_vm._e(),(_vm.showConnectionMenuModal)?_c('ConnectionMenuModal',{attrs:{"business-id":_vm.businessId,"menu-id":_vm.connectionMenuId,"menu-name":_vm.connectionMenuName,"selected-services":_vm.selectedOutlets,"source-id":_vm.connectionMenuSourceId,"source-is-editable":_vm.connectionMenuSourceIsEditable,"source-is-integration":_vm.connectionMenuSourceIsIntegration,"on-success":_vm.reloadData,"on-close":function () { return (_vm.showConnectionMenuModal = false); }}}):_vm._e(),_c('MolPagination',{class:_vm.css('pagination'),attrs:{"current-page":_vm.pagination.currentPage,"last-page":_vm.pagination.lastPage,"per-page":_vm.pagination.perPage,"total":_vm.pagination.total,"on-navigate":function (page) { return _vm.getMenuList(page); }}}),(_vm.deleteModal)?_c('MolModalConfirm',{attrs:{"title":_vm.t('module.menu.main.table.delete_menu_confirmation_title', {
        menuName: _vm.deleteMenuData.name,
      }),"label":_vm.t('module.menu.main.table.delete_menu_confirmation_message'),"confirm-button-label":_vm.t('module.menu.main.table.delete_menu_confirm'),"on-click-cancel":function () { return (_vm.deleteModal = false); },"on-click-confirm":function () { return _vm.deleteMenuItem(_vm.deleteMenuData.id); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }